import React from 'react';
import Galena from '../assets/galena.svg'

const PageHero = ({title}) => {

  return (
    <section className="banner banner--page">
      <div className="banner-graphic banner-graphic--page">
        <a href="/">
          <Galena />
        </a>
      </div>
      <div className="banner-text-container">
        <h1 className="banner-text uk-text-left">
          <div className="banner-text-component uk-text-default">
            Lisa Hansen & Ryan Gallagher
          </div>
          <div className="banner-text-component">
            {title}
          </div>
        </h1>
      </div>
    </section>
  )
}

export default PageHero
